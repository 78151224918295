// import React from "react";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import "./about.css";
// import Footer from "../components/Footer";
// import StickyNavbar from "../components/Navbar";
// import topImage from "../images/b1.jpg";
// import topImage1 from "../images/b2.jpg";

// function About() {
//   return (
//     <div className="about-section">
//       <StickyNavbar />
//       <h1 className="heading">Welcome to evLabs </h1>
//       {/* <div className="about-top-image">
//         <img src={topImage} alt="About Us" />
//       </div> */}

//       <Container className="tum">
//         <Row>
//           <Col md={6}>
//             <div className="about-images">
//               {/* You can add more images here if needed */}
//               <img src={topImage} alt="About Us 2" />
//             </div>
//           </Col>
//           <Col
//             md={6}
//             className="d-flex align-items-center justify-content-center"
//           >
//             <div className="about-description ">
//               <p className="desc">
//                 Welcome to evLabs India, headquartered in New Delhi. With
//                 extensive expertise in electronic circuit design, we provide
//                 comprehensive end-to-end solutions in Data Acquisition (DAQ)
//                 systems, system integration, and sensor conditioning. Our
//                 capabilities span low-power embedded systems, advanced analog
//                 front-end designs, and bespoke signal processing algorithms
//                 utilizing cutting-edge Machine Learning (ML), Artificial
//                 Intelligence (AI), and Digital Signal Processing (DSP)
//                 technologies. We develop robust Proof of Concept (PoC) systems
//                 and test beds, ensuring seamless integration and validation. Our
//                 services include consulting on Commercial Off-The-Shelf (COTS)
//                 products, and we deliver industrial and military-grade circuit
//                 and PCB designs. Our dedicated team, with experience from
//                 esteemed institutions like IITs, NITs, and the defense sector,
//                 drives innovation and excellence. Partner with evLabs India to
//                 pioneer transformative solutions that transcend boundaries and
//                 redefine possibilities in electronic circuit technology.
//               </p>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <Footer />
//     </div>
//   );
// }

// export default About;

import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./about.css";
import Footer from "../components/Footer";
import StickyNavbar from "../components/Navbar";
import IotVideo from "../assets/videos/e.mp4"; // Ensure the path is correct

function About({ showNavbar = true, showFooter = true }) {
  return (
    <div>
      <div className="about-section">
        {showNavbar && <StickyNavbar />}
        <Container className="tum m-5">
          <Row>
            <Col md={6} className="d-flex justify-content-center">
              <div className="about-video-container">
                <video className="about-video" autoPlay loop muted>
                  <source src={IotVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="about-description">
                <p className="desc">
                  Welcome to evLabs India, headquartered in New Delhi. With
                  extensive expertise in electronic circuit design, we provide
                  comprehensive end-to-end solutions in Data Acquisition (DAQ)
                  systems, system integration, and sensor conditioning. Our
                  capabilities span low-power embedded systems, advanced analog
                  front-end designs, and bespoke signal processing algorithms
                  utilizing cutting-edge Machine Learning (ML), Artificial
                  Intelligence (AI), and Digital Signal Processing (DSP)
                  technologies. We develop robust Proof of Concept (PoC) systems
                  and test beds, ensuring seamless integration and validation.
                  Our services include consulting on Commercial Off-The-Shelf
                  (COTS) products, and we deliver industrial and military-grade
                  circuit and PCB designs. Our dedicated team, with experience
                  from esteemed institutions like IITs, NITs, and the defense
                  sector, drives innovation and excellence. Partner with evLabs
                  India to pioneer transformative solutions that transcend
                  boundaries and redefine possibilities in electronic circuit
                  technology.
                </p>
                <button className="btn btn-primary ms-3">Learn More</button>{" "}
                {/* Add margin to the left */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {showFooter && <Footer />}
    </div>
  );
}

export default About;
