// import React, { Suspense, lazy } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import Iot from "../images/home11.jpg";
// import "./Home.css";
// import StickyNavbar from "../components/Navbar";
// import Footer from "../components/Footer";

// const BusinessUnits = lazy(() => import("../components/businessUnit"));
// const ExperiencePage = lazy(() => import("../components/Experties"));

// function HomePage() {
//   return (
//     <div className="homepage">
//       <StickyNavbar />
//       <Container fluid>
//         <Row>
//           <Col xs={12} className="full-width-col">
//             <img src={Iot} alt="Top" className="cover-image" />
//           </Col>
//         </Row>
//       </Container>
//       <Suspense fallback={<div>Loading...</div>}>
//         <BusinessUnits />
//         <ExperiencePage />
//       </Suspense>
//       <Footer />
//     </div>
//   );
// }

// export default HomePage;
import React, { Suspense, lazy } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css";
import StickyNavbar from "../components/Navbar";
import Footer from "../components/Footer";
import IotVideo from "../assets/videos/d.mp4";
import About from "./About";
import Expertise from "../components/Experties";
import Banner from "../images/b18.png";
import Banner1 from "../images/b16.png";
import Banner2 from "../images/b17.png";

// Lazy loaded components
const BusinessUnits = lazy(() => import("../components/businessUnit"));
const ExperiencePage = lazy(() => import("../components/Experties"));

function HomePage() {
  return (
    <div className="homepage">
      <StickyNavbar />
      <Container fluid>
        <Row>
          <Col xs={12} className="full-width-col">
            <video className="cover-video" autoPlay loop muted>
              <source src={IotVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Col>
        </Row>
      </Container>

      {/* Banner section below the Container */}

      <div className="carousel-container">
        <Carousel
          controls={false}
          indicators={false}
          interval={3000}
          pause={false}
        >
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={Banner}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={Banner1}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-image"
              src={Banner2}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <About showNavbar={false} showFooter={false} />
      </Suspense>
      <Expertise limit={2} />
      <BusinessUnits />
      <Footer />
    </div>
  );
}

export default HomePage;
