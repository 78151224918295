import React from "react";
import "./experties.css"; // Make sure to import your CSS file
import { Button } from "react-bootstrap";

// Expertise card data in JSON format
const expertiseData = [
  {
    id: 1,
    title: "Software Design",
    text: "evLabs India's product engineering team excels in system software development, covering everything from specifications to independent testing. Our expertise spans microprocessors, real-time operating systems, low-level system software, and OS internals, enabling us to deliver robust embedded engineering solutions tailored to the specific needs of our clients.",
  },
  {
    id: 2,
    title: "Electronic Circuit Technology Design",
    text: "evLabs India specializes in electronic circuit technology design, providing comprehensive services from benchmarking to advanced 3D printing. Our expertise ensures rapid validation and refinement of prototypes, enabling the creation of innovative solutions that meet the stringent demands of modern electronic applications.",
  },
  {
    id: 3,
    title: "Mechanical Engineering and 3D Printing Solutions",
    text: "evLabs India leads in mechanical engineering and 3D printing, offering services from design to rapid prototyping. Our advanced 3D printing capabilities allow for swift validation and iteration, ensuring that prototypes meet the highest standards of accuracy and functionality.",
  },
  {
    id: 4,
    title: "Single Board Computer",
    text: "evLabs India’s expertise in single board computer development includes everything from initial specifications to independent testing. Our team’s knowledge of microprocessors, real-time operating systems, and OS internals allows us to deliver reliable, high-performance embedded systems tailored to the specific needs of our clients.",
  },
  {
    id: 5,
    title: "Data Acquisition System",
    text: "evLabs India offers advanced data acquisition system design, leveraging our expertise in electronic circuit technology. Our comprehensive approach ensures the creation of precise, efficient systems that capture critical data accurately.",
  },
  {
    id: 6,
    title: "Ultra Semantic System Design",
    text: "evLabs India specializes in ultra-semantic system design, creating sophisticated, highly efficient systems that meet the unique demands of advanced computing environments.",
  },
  {
    id: 7,
    title: "FPGA | ASIC | Documentation Board Application",
    text: "evLabs India provides expertise in FPGA and ASIC design, as well as documentation board applications. Our services encompass everything from design to final implementation, ensuring fully optimized, high-performance solutions.",
  },
];

const Expertise = ({ limit }) => {
  return (
    <section className="m-4">
      <div className="flex-container">
        {/* Left side text section */}
        <div className="text-section">
          <h2 className="text-4xl font-bold" style={{ color: "#3498db" }}>
            <b>Our Expertise</b>
          </h2>

          <p className="text-lg">
            We offer innovative solutions in{" "}
            <b style={{ color: "#3498db" }}>
              AI, ML, and hard disk acquisition{" "}
            </b>
            , featuring advanced multiprocessing capabilities and beyond.
          </p>
          <Button>Read More</Button>
        </div>

        {/* Right side card section */}
        <div className="card-section">
          <div className="grid-container mt-3">
            {expertiseData.slice(0, limit).map((item) => (
              <div key={item.id} className="card experience-card">
                <div className="card-body">
                  <h5 className="card-title">{item.title}</h5>
                  <p className="card-text">{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expertise;
