import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Log from "../../images/2.jpg";
import axios from "axios";
import "./signUp.css";

const SignUp = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const signupResponse = await axios.post(
        "http://localhost:5000/api/signup",
        { username, email, password },
        { headers: { "Content-Type": "application/json" } }
      );

      if (signupResponse.data.success && signupResponse.data.token) {
        console.log("SignUp successful. User data:", signupResponse.data);

        // Save the token and userId in localStorage
        localStorage.setItem("token", signupResponse.data.token);
        const userId = signupResponse.data.userId;
        localStorage.setItem("userId", userId);

        if (!userId) {
          throw new Error("User ID is undefined in the signup response.");
        }

        // Fetch user data
        const userResponse = await axios.get(
          `http://localhost:5000/api/user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${signupResponse.data.token}`, // Add the token for authorization
            },
          }
        );

        console.log("Fetched user data:", userResponse.data);
        setUserData(userResponse.data.user);

        // Redirect to dashboard after fetching the data
        navigate("/dashboard");
      }
    } catch (error) {
      console.error(
        "Error signing up or fetching user data:",
        error.response?.data || error.message
      );
      setErrorMessage(
        error.response?.data?.message || "SignUp failed. Please try again."
      );
    }
  };

  return (
    <div className="login">
      <div className="login-container">
        <div className="login-left">
          <h2 className="login-title">SignUp</h2>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submit-button">
              SignUp
            </button>
          </form>

          {userData && (
            <div className="user-data">
              <h3>User Data:</h3>
              <pre>{JSON.stringify(userData, null, 2)}</pre>
            </div>
          )}
        </div>

        <div className="login-right">
          <img src={Log} alt="Login illustration" className="login-image" />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
