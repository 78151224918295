import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Log from "../../images/2.jpg";
import axios from "axios";
import "./login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const loginResponse = await axios.post(
        "http://localhost:5000/api/login",
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      );

      if (loginResponse.data.success && loginResponse.data.token) {
        console.log("Login successful. User data:", loginResponse.data);

        // Save the token and userId in localStorage
        localStorage.setItem("token", loginResponse.data.token);
        const userId = loginResponse.data.userId;
        localStorage.setItem("userId", userId);

        if (!userId) {
          throw new Error("User ID is undefined in the login response.");
        }

        // Fetch user data
        const userResponse = await axios.get(
          `http://localhost:5000/api/user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${loginResponse.data.token}`, // Add the token for authorization
            },
          }
        );

        console.log("Fetched user data:", userResponse.data);
        setUserData(userResponse.data.user);

        // Redirect to dashboard after fetching the data
        navigate("/dashboard");
      }
    } catch (error) {
      console.error(
        "Error logging in or fetching user data:",
        error.response?.data || error.message
      );
      setErrorMessage(
        error.response?.data?.message || "Login failed. Please try again."
      );
    }
  };

  return (
    <div className="login">
      <div className="login-container">
        <div className="login-left">
          <h2 className="login-title">Login</h2>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submit-button">
              Login
            </button>
          </form>

          {userData && (
            <div className="user-data">
              <h3>User Data:</h3>
              <pre>{JSON.stringify(userData, null, 2)}</pre>
            </div>
          )}
        </div>

        <div className="login-right">
          <img src={Log} alt="Login illustration" className="login-image" />
        </div>
      </div>
    </div>
  );
};

export default Login;
